import React from 'react';

import { Recipe } from '../../../../../interfaces/CustomerRecipes';

import classes from './CustomerRecipesTable.module.scss';
import { TitleRow } from './TitleRow/TitleRow';
import { RecipeRow } from './RecipeRow/RecipeRow';

type CustomerRecipesTableProps = {
  recipes: Recipe[];
};

const CustomerRecipesTable = ({ recipes }: CustomerRecipesTableProps) => {
  return (
    <div className={classes.recipesTable}>
      <TitleRow />
      {recipes.map((recipe) => (
        <RecipeRow key={recipe.id} recipe={recipe} />
      ))}
    </div>
  );
};

export { CustomerRecipesTable };
