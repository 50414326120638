import { apiService } from '../../shared/services/apiService';
import { AffiliateInfo } from '../interfaces/AffiliateInfo';

export type GetAffiliateListResponse = {
  customerSchoolGroups: AffiliateInfo[];
};

export type AddAffiliate = {
  name: string;
};

export type EditAffiliate = {
  name: string;
};

export const affiliateService = {
  async getAffiliateList(entityId: number, isRunGlobalSpinner?: boolean) {
    const resp = await apiService.get<GetAffiliateListResponse>(
      `/api/entity/${entityId}/school`,
      { isRunGlobalSpinner },
    );

    return resp.data;
  },

  async addNewAffiliate(entityId: number, affiliate: AddAffiliate) {
    const resp = await apiService.post(`/api/entity/${entityId}/school`, affiliate);

    return resp.data;
  },

  async editAffiliate(
    entityId: number,
    affiliateId: number,
    affiliateInfo: EditAffiliate,
  ) {
    const resp = await apiService.put(
      `/api/entity/${entityId}/school/${affiliateId}`,
      affiliateInfo,
    );

    return resp.data;
  },

  async changeAffiliateStatus(entityId: number, affiliateId: number, status: boolean) {
    const params = { status };

    const resp = await apiService.put(
      `/api/entity/${entityId}/school/${affiliateId}/status`,
      undefined,
      { params },
    );

    return resp.data;
  },

  async getAffiliateListForSelfSetup(entityId: number) {
    const params = { entityId };

    const resp = await apiService.get<GetAffiliateListResponse>(
      `api/sign-up/customers/schools`,
      { params },
    );

    return resp.data;
  },

  // for superAdmin
  async getAffiliateForAllEntity(isRunGlobalSpinner?: boolean) {
    const resp = await apiService.get<GetAffiliateListResponse>(
      '/api/customers/schools',
      {
        isRunGlobalSpinner,
      },
    );

    return resp.data;
  },
};
