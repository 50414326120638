import { useEffect, useMemo, useState } from 'react';

import { GroupInfo } from '../interfaces/GroupInfo';
import { Option } from '../../general-types';
import { customerGroupService } from '../services/customerGroupService';
import { addAllOption, transformToOptions } from '../../shared/utils/arrayUtils';

type UseGroupListReturn = {
  groupList: GroupInfo[];
  groupListOption: Option[];
  groupListOptionWithAll: Option[];
};

type UseGroupListProps = {
  onlyActive?: boolean;
  isRunGlobalSpinner?: boolean;
};

export const useGroupList = ({
  onlyActive = false,
  isRunGlobalSpinner,
}: UseGroupListProps = {}): UseGroupListReturn => {
  const [groupList, setGroupList] = useState<GroupInfo[]>([]);

  const initGroupList = async () => {
    const { groups } = await customerGroupService.getGroupList(isRunGlobalSpinner);

    if (onlyActive) {
      const activeGroups = groups.filter((group) => group.active);
      setGroupList(activeGroups);
    } else {
      setGroupList(groups);
    }
  };

  useEffect(() => {
    initGroupList();
  }, []);

  const groupListOption = useMemo(
    () => transformToOptions({ list: groupList, labelKey: 'name', valueKey: 'id' }),
    [groupList],
  );

  const groupListOptionWithAll = useMemo(
    () => addAllOption(groupListOption),
    [groupListOption],
  );

  return { groupList, groupListOption, groupListOptionWithAll };
};
