import React, { ElementType, MouseEventHandler } from 'react';

import { Paper, PaperProps } from '@mui/material';

import classNames from 'classnames';
import classes from './GridTableRow.module.scss';
import { PropsRewrite } from '../../../../utils-types';

type GridTableRowVariant = 'header' | 'row' | 'subRow' | 'filter' | 'unstyled';

type GridTableRowProps<TComponent extends ElementType> = {
  variant: GridTableRowVariant;
  component?: TComponent;
  customLayout?: boolean;
  dataTestId?: string;
  highlight?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

// tableGridLayout used in table scss to add unique layout for all row
// TODO separate row size style and border/shadow (in or out of box )
const GridTableRow = <TComponent extends ElementType>({
  children,
  variant,
  className,
  customLayout = false,
  highlight,
  dataTestId,
  onClick,
  ...props
}: PropsRewrite<PaperProps<TComponent>, GridTableRowProps<TComponent>>) => {
  const rowClasses = classNames(classes[variant], [classes.gridRow], className, {
    tableGridLayout: !customLayout,
    [classes.highlight]: highlight,
  });

  return (
    <Paper
      variant="roundedBox"
      className={rowClasses}
      data-test-id={dataTestId}
      onClick={onClick}
      {...props}
    >
      {children}
    </Paper>
  );
};

export { GridTableRow, GridTableRowProps, GridTableRowVariant };
