type DescriptionInfo = {
  title: string;
  description: string;
};

export const getDescriptionInfo = (isSuperAdmin: boolean): DescriptionInfo => {
  const entityInfo = {
    title: 'Set up your new password',
    description: `Set up your new password without logging out. The next time you log into the platform, please use the new password`,
  };

  const adminInfo = {
    title: 'Generate a new password and send it using various methods',
    description: `Instead of the user, you can generate a new password and provide it to them. 
        Then user will use this password during the login process`,
  };

  return isSuperAdmin ? adminInfo : entityInfo;
};
