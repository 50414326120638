import { useRef } from 'react';

export const useConfirm = <T extends any = boolean>() => {
  const resolvePromiseRef = useRef<((value: T) => void) | null>(null);

  const ask = (): Promise<T> => {
    return new Promise<T>((resolve) => {
      resolvePromiseRef.current = resolve;
    });
  };

  const answer = (info: T) => {
    if (resolvePromiseRef.current) {
      resolvePromiseRef.current(info);
      resolvePromiseRef.current = null;
    } else {
      throw new Error('do not call ask, resolvePromiseRef is not defined');
    }
  };

  return { ask, answer };
};
