import React from 'react';

import { useFormContext } from 'react-hook-form';

import {
  InputBox,
  LbAutocomplete,
  LbDatePicker,
  LbInput,
  LbSelect,
} from '../../../shared/components/form-components';
import { patterns } from '../../../shared/constants/patterns';
import { genderOptions } from '../../../shared/constants/user-info/gender';
import { states } from '../../../shared/constants/states';
import { validateMinAge } from '../../../shared/utils/reactHookFormUtils';
import { ScholasticUserDataFormValue } from '../../interfaces/ScholasticUserProfileFormValue';
import { AffiliateInfo } from '../../interfaces/AffiliateInfo';

type ScholasticProfileInputsProps = {
  affiliateList: AffiliateInfo[];
  isShowAddress?: boolean;
  className?: string;
};
// TODO use props control fix typescript error
const ScholasticProfileInputs = ({
  affiliateList,
  isShowAddress,
  className,
}: ScholasticProfileInputsProps) => {
  const { control } = useFormContext<ScholasticUserDataFormValue>();

  const wrongPhonePatternMessage = 'Invalid format: (752) 000 0000';

  const privateInfoProps = { InputProps: { className: 'private-info' } };

  return (
    <div className={className}>
      <InputBox title="Personal Data">
        <LbInput
          control={control}
          label="First Name"
          name="userData.user.firstName"
          rules={{
            required: 'First Name is required',
          }}
          {...privateInfoProps}
        />
        <LbInput
          control={control}
          label="Last Name"
          name="userData.user.lastName"
          rules={{
            required: 'Last Name is required',
            pattern: {
              value: patterns.ONLY_LETTERS,
              message: 'Last Name must contain only letters',
            },
          }}
          {...privateInfoProps}
        />
        <LbDatePicker
          control={control}
          label="Date of Birth"
          name="userData.customer.dob"
          openTo="year"
          rules={{ required: 'Date of Birth is required', validate: { validateMinAge } }}
        />
        <LbAutocomplete
          control={control}
          label="Affiliate"
          name="userData.customer.school"
          options={affiliateList}
          getOptionLabel={(school) => school.name}
          isOptionEqualToValue={(school, value) => school.id === value.id}
          rules={{ required: 'Affiliate is required' }}
        />
        <LbInput
          control={control}
          label="Email address"
          name="userData.user.email"
          rules={{
            required: 'Email address is required',
            pattern: {
              value: patterns.EMAIL,
              message: 'Invalid email address',
            },
          }}
          {...privateInfoProps}
        />
        <LbInput
          control={control}
          label="Cell Phone"
          name="userData.customer.cellPhone"
          rules={{
            required: 'Cell Phone is required',
            pattern: {
              value: patterns.PHONE,
              message: wrongPhonePatternMessage,
            },
          }}
          {...privateInfoProps}
        />
      </InputBox>
      <InputBox title="BMR Calculation">
        <LbInput
          control={control}
          label="Height (inches)"
          name="userData.customer.height"
          rules={{
            required: 'Height is required',
            min: {
              message: 'Height value must be between 1 and 140',
              value: 0,
            },
            max: {
              message: 'Height value must be between 1 and 140',
              value: 140,
            },
          }}
          type="number"
        />
        <LbInput
          control={control}
          label="Weight (lbs)"
          name="userData.customer.weight"
          rules={{
            required: 'Weight is required',
            min: {
              message: 'Weight value must be between 1 and 600',
              value: 0,
            },
            max: {
              message: 'Weight value must be between 1 and 600',
              value: 600,
            },
          }}
          type="number"
        />
        <LbSelect
          control={control}
          label="Gender"
          name="userData.customer.gender"
          options={genderOptions}
          rules={{ required: 'Gender is required' }}
        />
      </InputBox>
      {isShowAddress && (
        <InputBox title="Address(optional) ">
          <LbInput
            control={control}
            label="Address"
            name="userData.customer.address"
            {...privateInfoProps}
          />
          <LbInput
            control={control}
            label="City"
            name="userData.customer.city"
            {...privateInfoProps}
          />
          <LbAutocomplete
            control={control}
            label="State"
            name="userData.customer.state"
            options={states}
            {...privateInfoProps}
          />
          <LbInput
            control={control}
            label="Zip Code"
            name="userData.customer.zipCode"
            type="number"
            {...privateInfoProps}
          />
        </InputBox>
      )}
    </div>
  );
};

export { ScholasticProfileInputs };
