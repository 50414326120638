import React, { useEffect, useState } from 'react';

import { Button } from '@mui/material';
import { useToggle } from 'react-use';

import classes from './CoachPinCodeBlock.module.scss';
import { InfoBox } from '../../../../shared/components/ui-components';
import { SetUpPinCodeModal } from '../../../components/SetUpPinCodeModal/SetUpPinCodeModal';
import { pinCodeService } from '../../../services/pinCodeService';

type CoachPinCodeBlockProps = {
  userId: number;
};

const CoachPinCodeBlock = ({ userId }: CoachPinCodeBlockProps) => {
  const [isShowPinCodeModal, toggleIsShowPinCodeModal] = useToggle(false);
  const [currentPinCode, setCurrentPinCode] = useState<string | undefined>();
  const [isShowPinCode, toggleIsShowPinCode] = useToggle(false);

  const hasCurrentPinCode = Boolean(currentPinCode);

  const getCoachPinCode = async () => {
    const resp = await pinCodeService.getCoachPinCode(userId);

    setCurrentPinCode(resp?.pincode);
  };

  useEffect(() => {
    getCoachPinCode();
  }, []);

  return (
    <>
      <InfoBox title="Pin Code">
        <div className={classes.row}>
          <div className={classes.rowLeft}>
            <div className={classes.showPinCodeContainer}>
              <p className={classes.title}>Set up a PIN code as a quick access</p>
              {hasCurrentPinCode && (
                <>
                  <span className={classes.pinCode}>
                    {isShowPinCode ? currentPinCode : '******'}
                  </span>
                  <Button variant="text" size="small" onClick={toggleIsShowPinCode}>
                    {isShowPinCode ? 'Hide' : 'Show'} Pin code
                  </Button>
                </>
              )}
            </div>
            <p className={classes.description}>
              Set a PIN code, only for corporate accounts
            </p>
          </div>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={toggleIsShowPinCodeModal}
          >
            Set up Pin Code
          </Button>
        </div>
      </InfoBox>

      {isShowPinCodeModal && (
        <SetUpPinCodeModal
          coachId={userId}
          onClose={toggleIsShowPinCodeModal}
          hasCurrentPinCode={hasCurrentPinCode}
          setCurrentPinCode={setCurrentPinCode}
        />
      )}
    </>
  );
};

export { CoachPinCodeBlock };
