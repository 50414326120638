import React from 'react';
import PropTypes from 'prop-types';

import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

const OpenCloseArrow = ({ open }) => {
  if (open) {
    return <KeyboardArrowUpRoundedIcon fontSize="medium" color="primary" />;
  } else {
    return <KeyboardArrowDownRoundedIcon fontSize="medium" />;
  }
};

OpenCloseArrow.propTypes = {
  open: PropTypes.bool,
};

export { OpenCloseArrow };
