import React from 'react';

import { useFormContext } from 'react-hook-form';

import {
  GridTableCell,
  GridTableRow,
} from '../../../../../../../shared/components/table-components';
import { Nutrient } from '../../../../../../../shared/components/ui-components';
import { MacroPlanStepFromValue } from '../../NgzMealPlanTab.settings';
import { getRoundedNumber } from '../../../../../../../shared/utils/mathUtils';

type CaloriesPercentsRowProps = {
  nameIndex: number;
};

const digits = 1;

const CaloriesPercentsRow = ({ nameIndex }: CaloriesPercentsRowProps) => {
  const { watch } = useFormContext<MacroPlanStepFromValue>();

  const [caloriesPercentProtein, caloriesPercentCarbs, caloriesPercentFat] = watch([
    `mealPlans.${nameIndex}.caloriesPercentProtein`,
    `mealPlans.${nameIndex}.caloriesPercentCarbs`,
    `mealPlans.${nameIndex}.caloriesPercentFat`,
  ]);

  return (
    <GridTableRow variant="subRow">
      <GridTableCell variant="title">Calories, %</GridTableCell>
      <GridTableCell variant="title">
        <Nutrient nutrient="proteins">
          {getRoundedNumber(caloriesPercentProtein, digits)}%
        </Nutrient>
      </GridTableCell>
      <GridTableCell variant="title">
        <Nutrient nutrient="carbs">
          {getRoundedNumber(caloriesPercentCarbs, digits)}%
        </Nutrient>
      </GridTableCell>
      <GridTableCell variant="title">
        <Nutrient nutrient="fats">
          {getRoundedNumber(caloriesPercentFat, digits)}%
        </Nutrient>
      </GridTableCell>
      {/* for calories column   */}
      <div />
    </GridTableRow>
  );
};

export { CaloriesPercentsRow };
