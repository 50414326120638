import React from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import { useUpdateEffect } from 'react-use';
import classes from './MealInfoBlock.module.scss';
import {
  LbInput,
  LbRadioGroup,
} from '../../../../../../../shared/components/form-components';
import { MacroPlanStepFromValue } from '../../NgzMealPlanTab.settings';
import { mealPlanService } from '../../../../../../services/mealPlanService';
import { getRouteParam } from '../../../../../../../shared/utils/angularUtils';
import { MealTabTitle } from '../../meal-table-components';
import { getFilteredTruthyFieldsObj } from '../../../../../../../shared/utils/sortingAndGroupingUtil';

type MealInfoBlockProps = {
  isEditMode: boolean;
  setIsEditMode: (value: boolean) => void;
  nameIndex: number;
  onKeepClick: () => void;
  onSubmit: (isImmediately: boolean) => void;
};

const MealInfoBlock = ({
  nameIndex,
  isEditMode,
  setIsEditMode,
  onKeepClick,
  onSubmit,
}: MealInfoBlockProps) => {
  const { control, setValue } = useFormContext<MacroPlanStepFromValue>();
  const mealPlans = useWatch({
    control,
    name: 'mealPlans',
  });
  const mealPlanPlans = useWatch({
    control,
    name: `mealPlans.${nameIndex || 0}.mealPlan`,
  });

  const currentPlan = mealPlans[nameIndex];
  const customerId = getRouteParam('id');
  const isNewMealPlan = !currentPlan.originId;

  useUpdateEffect(() => {
    handleRadioChange(mealPlanPlans);
  }, [mealPlanPlans]);

  const handleRadioChange = async (newValue: number) => {
    if (currentPlan?.originId && newValue) {
      switchAmountOfMealsForExitPlan(newValue);
    } else {
      switchAmountOfMealsForNewPlan(newValue);
    }
  };

  const switchAmountOfMealsForExitPlan = async (newValue: number) => {
    const response = await mealPlanService.switchAmountOfMeals(
      customerId,
      currentPlan.originId,
      newValue,
    );
    const { name } = currentPlan;

    setValue(`mealPlans.${nameIndex}`, { ...response, name });
  };

  const switchAmountOfMealsForNewPlan = async (newValue: number) => {
    const result = await mealPlanService.buildMealPlansMacros(customerId, newValue);

    // omit update field added on add meal plan
    const filteredMealPlan = getFilteredTruthyFieldsObj(result.mealPlans[0]);
    const formattedMealPlan = {
      ...currentPlan,
      ...filteredMealPlan,
    };

    setValue(`mealPlans.${nameIndex}`, formattedMealPlan);
  };

  return (
    <MealTabTitle
      isEditMode={isEditMode}
      mealPlanName={currentPlan.name}
      hideKeepButton={isNewMealPlan}
      onEditClick={() => setIsEditMode(true)}
      onKeepClick={onKeepClick}
      onSubmit={onSubmit}
    >
      <div className={classes.mealEditInfo}>
        <LbInput
          variant="outlined"
          control={control}
          size="small"
          name={`mealPlans.${nameIndex}.name`}
        />
        <LbRadioGroup
          row
          control={control}
          name={`mealPlans.${nameIndex}.mealPlan`}
          options={[
            { value: 6, label: '3 Meals & 3 Snacks' },
            { value: 5, label: '3 Meals & 2 Snacks' },
          ]}
        />
      </div>
    </MealTabTitle>
  );
};

export { MealInfoBlock };
