import React, { useState } from 'react';

import { Button } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import CheckIcon from '@mui/icons-material/Check';

import classes from './SendMagicLinkRow.module.scss';
import { TitleWithDescription } from '../../../../shared/components/text-formation-components';
import { authService } from '../../../services/authService';

type SendMagicLinkRowProps = {
  userEmail: string;
};

const SendMagicLinkRow = ({ userEmail }: SendMagicLinkRowProps) => {
  const [isRecoveryLinkSent, setIsRecoveryLinkSent] = useState(false);
  const RecoveryLinkButtonIcon = isRecoveryLinkSent ? <CheckIcon /> : null;

  const handleSendMagicLink = async () => {
    await authService.resetPassword(userEmail);
    enqueueSnackbar(
      'The magic link has been successfully sent to the user`s email address.',
      { variant: 'success' },
    );
    setIsRecoveryLinkSent(true);
  };

  return (
    <div className={classes.row}>
      <TitleWithDescription
        title="Send a magic link to the user's email"
        description="The user will receive a link, allowing them to create a new password"
        titleFont="body-14-bold"
      />
      <Button
        variant="outlined"
        size="small"
        onClick={handleSendMagicLink}
        startIcon={RecoveryLinkButtonIcon}
        disabled={isRecoveryLinkSent}
      >
        Send Recovery Link
      </Button>
    </div>
  );
};

export { SendMagicLinkRow };
