import React from 'react';

import { useFormContext } from 'react-hook-form';
import { observer } from 'mobx-react';

import {
  RangeInput,
  MultipleComboBox,
  LbRadioGroup,
} from '../../../../../../../components/form-components';
import { CustomFilterFormValues } from '../../AddCustomFilterForm.settings';
import { customFilterStore } from '../../../../../stores/customFilterStore';
import { UserGroupFilter } from './UserGroupFilter/UserGroupFilter';
import { AffiliateGroupFilter } from './AffiliateGroupFilter/AffiliateGroupFilter';
import { genderOptionsWithBoth } from '../../../../../../../constants/user-info/gender';
import { ModalInputBox, ModalInputGroup } from '../../../../../../../components/modals';
import { workoutOptions } from '../../../../../../../constants/user-info/workout';

const PersonalInfo = observer(() => {
  const { selectedResourceType } = customFilterStore;
  const { CUSTOMERS, SCHOLASTIC } = selectedResourceType;
  const { control, register } = useFormContext<CustomFilterFormValues>();

  return (
    <ModalInputBox title="Personal Info">
      <input
        {...register('filters.PERSONAL_INFO.filterType', { value: 'PERSONAL_INFO' })}
        hidden
      />
      <ModalInputGroup>
        <LbRadioGroup
          row
          control={control}
          label="Gender"
          name="filters.PERSONAL_INFO.genderType"
          options={genderOptionsWithBoth}
          defaultValue=""
        />
        <RangeInput
          control={control}
          startProps={{ name: 'filters.PERSONAL_INFO.age.from' }}
          endProps={{ name: 'filters.PERSONAL_INFO.age.to' }}
          min={12}
          max={95}
        />
      </ModalInputGroup>
      {CUSTOMERS && <UserGroupFilter />}
      {SCHOLASTIC && <AffiliateGroupFilter />}
      <MultipleComboBox
        control={control}
        size="small"
        variant="outlined"
        name="filters.PERSONAL_INFO.workoutTypes"
        label="Workout Type"
        options={workoutOptions}
        limitTags={3}
      />
    </ModalInputBox>
  );
});

export { PersonalInfo };
