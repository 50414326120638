/* eslint-disable func-names */
import axios, {
  AxiosInstance,
  CreateAxiosDefaults,
  CustomParamsSerializer,
  AxiosError,
  AxiosResponse,
} from 'axios';
import queryString from 'query-string';
import { getService } from 'react-in-angularjs';

import { apiStatusStore } from '../stores/apiStatusStore';
import { clearUserInfo } from '../utils/userInfoUtils';

const { loadingStart, loadingEnd, addErrorMessage } = apiStatusStore;

// TODO fix any type and refactoring LMP-2489
const stringifyParams: CustomParamsSerializer = (params) => {
  const options = { arrayFormat: 'comma', skipEmptyString: true } as const;

  return queryString.stringify(params, options);
};

const onErrorHandler = (error: AxiosError) => {
  loadingEnd(error.config?.isRunGlobalSpinner);

  if (error.response) {
    onResponseErrorHandler(error.response);
  } else if (error.request) {
    addErrorMessage(error.message);
  }

  return Promise.reject(error);
};

const onResponseErrorHandler = (response: AxiosResponse) => {
  if (!shouldUseResponseErrorHandler(response)) {
    return;
  }

  const responseData = parseErrorResponseData(response);
  const errorMessage = getErrorMessage(responseData);

  switch (response.status) {
    case 401: {
      const $rootScope = getService('$rootScope');
      const utilService = getService('utilService');

      clearUserInfo();

      utilService.goTo('/', { replaceHistory: true });
      $rootScope.$apply();
      break;
    }
    default: {
      addErrorMessage(errorMessage);
    }
  }
};

const shouldUseResponseErrorHandler = (response: AxiosResponse): boolean => {
  const { config, status } = response;

  return !config.ignoreErrorCodes?.includes(status);
};

const parseErrorResponseData = (response: AxiosResponse) => {
  const { responseType } = response.config;

  switch (responseType) {
    case 'text':
      return JSON.parse(response.data);
    case 'arraybuffer':
      return transformArraybufferToJson(response.data);
    default:
      return response.data;
  }
};

const transformArraybufferToJson = (arraybuffer: any) => {
  const decoder = new TextDecoder();

  const jsonString = decoder.decode(arraybuffer);

  return JSON.parse(jsonString);
};

const getErrorMessage = (responseData: any) => {
  const { message, error } = responseData;
  const isErrorMessage = message !== 'No message available';

  return isErrorMessage ? message : error;
};

const createClient = (clientConfig: CreateAxiosDefaults): AxiosInstance => {
  const client = axios.create({
    withCredentials: true,
    isRunGlobalSpinner: true,
    ignoreErrorCodes: null,
    paramsSerializer: {
      serialize: stringifyParams,
    },
    ...clientConfig,
  });

  client.interceptors.request.use((config) => {
    loadingStart(config.isRunGlobalSpinner);

    return config;
  }, onErrorHandler);

  client.interceptors.response.use((response) => {
    loadingEnd(response.config.isRunGlobalSpinner);
    return response;
  }, onErrorHandler);

  return client;
};

export const apiService = createClient({
  baseURL: process.env.APP_API_URL,
});

export const apiReportService = createClient({
  baseURL: process.env.APP_API_REPORTS_URL,
});

declare module 'axios' {
  export interface AxiosRequestConfig {
    isRunGlobalSpinner?: boolean;
    /**
     * @description not show error message for this error codes
     */
    ignoreErrorCodes?: number[] | null;
  }
}
