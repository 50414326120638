/* eslint-disable react/no-array-index-key */
import React, { ChangeEvent } from 'react';

import classNames from 'classnames';

import {
  FormControl,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  RadioProps,
} from '@mui/material';
import {
  FieldValues,
  Path,
  PathValue,
  UseControllerProps,
  useController,
} from 'react-hook-form';

import classes from './LbRadioGroup.module.scss';
import { RadioOption } from '../../../../general-types';
import {
  LbFormControlLabel,
  LbFormControlLabelProps,
} from '../LbFormControlLabel/LbFormControlLabel';

type LbRadioGroupProps<T extends FieldValues, TName extends Path<T>> = {
  label?: string;
  options: RadioOption[];
  radioColor?: RadioProps['color'];
  fullWidth?: boolean;
  align?: LbFormControlLabelProps['align'];
  className?: string;
} & UseControllerProps<T, TName> &
  Pick<RadioGroupProps, 'row'>;

const LbRadioGroup = <T extends FieldValues, TName extends Path<T>>({
  control,
  name,
  rules,
  defaultValue = null as PathValue<T, TName>,
  label,
  options,
  row,
  radioColor = 'primary',
  fullWidth,
  align,
  className,
}: LbRadioGroupProps<T, TName>) => {
  const {
    field: { onChange, ...field },
    fieldState: { error },
  } = useController<any>({ control, name, rules, defaultValue });

  const optionsBoxClasses = classNames(classes.optionBox, className, {
    [classes.rowFullWidth]: row && fullWidth,
  });

  const handlerChange = (event: ChangeEvent<HTMLInputElement>, selectedValue: string) => {
    const selectedOption = options.find(
      (option) => String(option.value) === selectedValue,
    );

    if (!selectedOption) {
      throw new Error(`Value ${selectedValue} not found`);
    }

    onChange(selectedOption.value);
  };

  return (
    <FormControl error={!!error} fullWidth={fullWidth}>
      {!!label && <FormLabel className={classes.groupLabel}>{label}</FormLabel>}
      <RadioGroup
        {...field}
        onChange={handlerChange}
        row={row}
        className={optionsBoxClasses}
        tabIndex={-1}
      >
        {options.map((item, index) => (
          <LbFormControlLabel
            key={index}
            value={item.value}
            control={<Radio size="medium" color={radioColor} />}
            label={item.label}
            align={align}
          />
        ))}
      </RadioGroup>
      {error?.message && (
        <FormHelperText className={classes.helperText}>{error?.message}</FormHelperText>
      )}
    </FormControl>
  );
};

export { LbRadioGroup };
