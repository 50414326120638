import { useEffect, useState } from 'react';

import { AffiliateInfo } from '../../../../../interfaces/AffiliateInfo';
import { affiliateService } from '../../../../../services/affiliateService';

export const useAffiliateList = (entityId: number) => {
  const [affiliateList, setAffiliateList] = useState<AffiliateInfo[]>([]);

  const getAffiliateList = async () => {
    const { customerSchoolGroups } =
      await affiliateService.getAffiliateListForSelfSetup(entityId);
    setAffiliateList(customerSchoolGroups);
  };

  useEffect(() => {
    getAffiliateList();
  }, []);

  return { affiliateList };
};
