import React from 'react';

import { Button, Paper } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import { useToggle } from 'react-use';

import classes from './MealTabTitle.module.scss';
import { TimingChoiceModal } from '../../../../../../components/TimingChoiceModal/TimingChoiceModal';
import { openConfirmationModal } from '../../../../../../../shared/features/confirmation';

type MealTabTitleProps = {
  isEditMode: boolean;
  mealPlanName: string;
  children?: React.ReactNode;
  hideKeepButton?: boolean;
  onEditClick: () => void;
  onKeepClick: () => void;
  onSubmit: (isImmediately: boolean) => void;
};

const MealTabTitle = ({
  isEditMode,
  mealPlanName,
  children,
  hideKeepButton,
  onEditClick,
  onKeepClick,
  onSubmit,
}: MealTabTitleProps) => {
  const [isSavedModalOpen, toggleSavedModalOpen] = useToggle(false);

  const handleKeepClick = () => {
    openConfirmationModal({
      title: 'Are you sure you want to keep existing data?',
      confirmBtnConfig: {
        text: 'Keep',
        onClick: onKeepClick,
      },
    });
  };

  return (
    <Paper variant="roundedBox" className={classes.box}>
      {isEditMode && children ? (
        children
      ) : (
        <p className={classes.mealPlanName}>{mealPlanName}</p>
      )}
      {isEditMode ? (
        <div className={classes.mealEditActions}>
          {!hideKeepButton && (
            <Button variant="outlined" size="medium" onClick={handleKeepClick}>
              Keep existing
            </Button>
          )}
          <Button variant="contained" size="medium" onClick={toggleSavedModalOpen}>
            Save & Update
          </Button>
        </div>
      ) : (
        <Button
          variant="contained"
          size="medium"
          startIcon={<CreateIcon />}
          onClick={onEditClick}
        >
          Edit
        </Button>
      )}
      <TimingChoiceModal
        open={isSavedModalOpen}
        title="Select how you want macro plan changes to update (make sure that you have correct meal plan in the meal schedule):"
        onSubmit={() => {
          onSubmit(true);
          toggleSavedModalOpen();
        }}
        onTomorrowSubmit={() => {
          onSubmit(false);
          toggleSavedModalOpen();
        }}
        onClose={toggleSavedModalOpen}
      />
    </Paper>
  );
};

export { MealTabTitle };
