import React, { useEffect } from 'react';

import { angularize } from 'react-in-angularjs';
import { Container, InputAdornment } from '@mui/material';
import { observer } from 'mobx-react';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { useForm } from 'react-hook-form';

import classes from './NgzCustomerRecipesTab.module.scss';
import { SystemProviders } from '../../../../../shared/providers';
import {
  EmptyList,
  LbPagination,
  PageTitle,
} from '../../../../../shared/components/ui-components';
import { CustomerRecipesTable } from './CustomerRecipesTable/CustomerRecipesTable';
import { customerRecipesStore } from '../../../../stores/customerRecipesStore';
import { LbInput } from '../../../../../shared/components/form-components';
import { CustomerRecipesFilterFormValues } from '../../../../interfaces/CustomerRecipes';
import { useAutoSubmitForm } from '../../../../../shared/hooks/useAutoSubmitForm';

type NgzCustomerRecipesTabProps = {
  customerId: number;
};

const NgzCustomerRecipesTab = observer(({ customerId }: NgzCustomerRecipesTabProps) => {
  const { recipes, getRecipes, setDefaultValues, filterMethods } = customerRecipesStore;
  const { control, handleSubmit, watch } = useForm<CustomerRecipesFilterFormValues>({
    defaultValues: filterMethods.filterData,
  });

  useEffect(() => {
    getRecipes(customerId);

    return () => {
      setDefaultValues();
    };
  }, []);

  useAutoSubmitForm(handleSubmit(filterMethods.applyFilters), watch, ['recipeName']);

  return (
    <SystemProviders>
      <Container>
        <PageTitle title="Recipes">
          <LbInput
            control={control}
            variant="outlined"
            size="medium"
            name="recipeName"
            placeholder="Search by Recipe"
            className={classes.searchInput}
            selectValueOnFocus
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchRoundedIcon fontSize="large" color="tertiary" />
                </InputAdornment>
              ),
            }}
          />
        </PageTitle>
        <CustomerRecipesTable recipes={recipes} />
        <LbPagination
          {...filterMethods.getPaginationProps()}
          elementsPerPage={filterMethods.elementsPerPage}
          totalElements={filterMethods.totalElements}
          variant="outBox"
        />
        <EmptyList
          show={filterMethods.isShowPlaceholder}
          isSearch={filterMethods.isSearchUsed}
          description="There are no recipes"
        />
      </Container>
    </SystemProviders>
  );
});

angularize(
  NgzCustomerRecipesTab,
  'ngzCustomerRecipesTab',
  angular.module('app.nutrition'),
  { customerId: '<' },
);

export { NgzCustomerRecipesTab };
