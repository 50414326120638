import React from 'react';

import { Paper } from '@mui/material';

import classes from './WorkoutMeals.module.scss';
import { MealHeaderRow, MealRow, MealTabTitle } from '../meal-table-components';

type WorkoutMealsProps = {
  isEditMode: boolean;
  setIsEditMode: (value: boolean) => void;
  onKeepClick: () => void;
  onSubmit: (isImmediately: boolean) => void;
};
const WorkoutMeals = ({
  isEditMode,
  setIsEditMode,
  onKeepClick,
  onSubmit,
}: WorkoutMealsProps) => {
  return (
    <Paper variant="roundedBox" className={classes.mealTable}>
      <MealTabTitle
        isEditMode={isEditMode}
        mealPlanName="Pre/Post Workout"
        onEditClick={() => setIsEditMode(true)}
        onKeepClick={onKeepClick}
        onSubmit={onSubmit}
      />
      <MealHeaderRow />
      <MealRow meal="preWorkout" mealFormKey="preWorkout" isEditMode={isEditMode} />
      <MealRow meal="postWorkout" mealFormKey="postWorkout" isEditMode={isEditMode} />
    </Paper>
  );
};

export { WorkoutMeals };
