import React from 'react';

import classes from './IngredientRow.module.scss';
import { Nutrient } from '../../../../../../../../shared/components/ui-components';
import {
  GridTableRow,
  GridTableCell,
} from '../../../../../../../../shared/components/table-components';
import { customerRecipeNutrientsConfig } from '../../CustomerRecipesTable.settings';
import { RecipeIngredient } from '../../../../../../../interfaces/CustomerRecipes';
import { TitleWithDescription } from '../../../../../../../../shared/components/text-formation-components';
import { getRoundedNumber } from '../../../../../../../../shared/utils/mathUtils';

type IngredientRowProps = {
  ingredient: RecipeIngredient;
};

const IngredientRow = ({ ingredient }: IngredientRowProps) => {
  const { name, amount, amountUnit, totalMacros } = ingredient;

  return (
    <GridTableRow
      variant="subRow"
      dataTestId="recipe-ingredient-row"
      className={classes.ingredientRow}
    >
      <GridTableCell variant="title">
        <TitleWithDescription
          title={name}
          description={`${amount} ${amountUnit}`}
          titleFont="inherit"
        />
      </GridTableCell>
      {customerRecipeNutrientsConfig.map((nutrient) => (
        <GridTableCell key={nutrient.valueKey}>
          <Nutrient nutrient={nutrient.valueKey}>
            {getRoundedNumber(totalMacros[nutrient.valueKey], 1)}
          </Nutrient>
        </GridTableCell>
      ))}
    </GridTableRow>
  );
};

export { IngredientRow };
