import React from 'react';

import { useToggle } from 'react-use';
import { observer } from 'mobx-react';

import { SendMagicLinkRow } from './SendMagicLinkRow/SendMagicLinkRow';
import { ChangePasswordRow } from './ChangePasswordRow/ChangePasswordRow';
import { loggedInUserDataStore } from '../../../shared/stores/loggedInUserDataStore';
import { InfoBox } from '../../../shared/components/ui-components';
import { ChangePasswordModal } from '../../../entity-management/components/ChangePasswordModal/ChangePasswordModal';

type CoachPasswordBlockProps = {
  userEmail: string;
  userId: number;
};

// TODO: Refactor this component when backend will be ready (make reusable component with EntityPasswordBlock).
const CoachPasswordBlock = observer(
  ({ userId: entityUserId, userEmail }: CoachPasswordBlockProps) => {
    const { isSuperAdmin, isEntity, isCoach } = loggedInUserDataStore;
    const [isShowChangePasswordModal, toggleShowChangePasswordModal] = useToggle(false);
    const isShowSendMagicLink = isSuperAdmin || isEntity;

    return (
      <InfoBox title="Set New Password">
        {isShowSendMagicLink && <SendMagicLinkRow userEmail={userEmail} />}
        <ChangePasswordRow onPasswordChange={toggleShowChangePasswordModal} />
        {isShowChangePasswordModal && (
          <ChangePasswordModal
            userId={entityUserId}
            isChangeCurrentUserPassword={isCoach}
            onClose={toggleShowChangePasswordModal}
          />
        )}
      </InfoBox>
    );
  },
);

export { CoachPasswordBlock };
