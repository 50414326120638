import { InputAdornment, Paper } from '@mui/material';

import React from 'react';
import { useFormContext } from 'react-hook-form';

import classes from './WaterSetupBlock.module.scss';
import { TitleWithDescription } from '../../../../../../../shared/components/text-formation-components';
import {
  LbInput,
  ReadOnlyInput,
} from '../../../../../../../shared/components/form-components';
import { Nutrient } from '../../../../../../../shared/components/ui-components';
import { MacroPlanStepFromValue } from '../../NgzMealPlanTab.settings';
import { validateNegativeNumber } from '../../../../../../../shared/utils/reactHookFormUtils';

type WaterSetupBlockProps = {
  nameIndex: number;
  isEditMode: boolean;
};

const WaterSetupBlock = ({ nameIndex, isEditMode }: WaterSetupBlockProps) => {
  const { control } = useFormContext<MacroPlanStepFromValue>();

  return (
    <Paper variant="roundedBox" className={classes.box}>
      <TitleWithDescription
        title="Water per day (oz)"
        description={
          <span>
            The standard water consumption is approx 0.5 oz per 1 lbs of body weight, but
            it can vary based on factors like exercise and propensity to sweat.
            <br />
            <br /> Check urine color to gauge hydration. Dark urine means more water is
            needed.
          </span>
        }
        titleFont="body-14-bold"
      />
      {isEditMode ? (
        <LbInput
          control={control}
          name={`mealPlans.${nameIndex}.totalWater`}
          size="small"
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Nutrient nutrient="water" />
              </InputAdornment>
            ),
          }}
          rules={{ validate: { validateNegativeNumber } }}
        />
      ) : (
        <Nutrient nutrient="water">
          <ReadOnlyInput
            control={control}
            name={`mealPlans.${nameIndex}.totalWater`}
            helperText={null}
          />
        </Nutrient>
      )}
    </Paper>
  );
};

export { WaterSetupBlock };
