import { NUTRIENTS_ENUM } from '../../shared/constants/NUTRIENTS_ENUM';
import { REGULAR_MEAL_NAME } from '../../shared/constants/mealNames';
import { apiService } from '../../shared/services/apiService';
import { renameFields } from '../../shared/utils/sortingAndGroupingUtil';
import { MealPlan } from '../interfaces/MealPlan';
import { PredefinedMealPlansInfo } from '../interfaces/PredefinedMealPlansInfo';

const fixFieldsNameConfig = {
  // backend field name: frontend field name
  crb: NUTRIENTS_ENUM.CARBS,
  fat: NUTRIENTS_ENUM.FATS,
  prt: NUTRIENTS_ENUM.PROTEINS,
  breakFast: REGULAR_MEAL_NAME.breakfast,
} as const;

export const mealPlanService = {
  async getPredefinedMealPlans(userId: number) {
    const resp = await apiService.get<PredefinedMealPlansInfo>(
      `/api/coach/user/${userId}/meal-plan/predefined`,
    );

    return renameFields(resp.data, fixFieldsNameConfig) as PredefinedMealPlansInfo;
  },

  async setPredefinedMealPlans(userId: number, predefinedMealPlanId: number) {
    const resp = await apiService.post<MealPlan>(
      `/api/coach/user/${userId}/meal-plan/${predefinedMealPlanId}`,
    );

    return resp.data;
  },
};
