import React from 'react';

import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { observer } from 'mobx-react';

import { goTo } from '../../../utils/angularUtils';
import { loggedInUserDataStore } from '../../../stores/loggedInUserDataStore';
import { MenuOptions } from '../../../../general-types';
import { LbMenu } from '../LbMenu/LbMenu';

const AddCustomerMenu = observer(() => {
  const { paymentFlowType, enabledModules, isCoach, isComplianceCoach } =
    loggedInUserDataStore;

  const isShowAddCustomerMenu = isCoach && !isComplianceCoach;

  if (!isShowAddCustomerMenu) {
    return null;
  }

  const menuOptions: MenuOptions[] = [
    {
      id: 1,
      label: 'Add Nutrition Member',
      onClick: () => goTo('/customer-setup'),
      isHide: !enabledModules.NUTRITION,
    },
    {
      id: 2,
      label: 'Add Affiliate Member',
      onClick: () => goTo('/scholastic-setup'),
      isHide: !enabledModules.SCHOLASTIC,
    },
    { id: 3, label: 'Add Supplement Member', onClick: () => goTo('/supplement-setup') },
    {
      id: 4,
      label: 'Add Pre Check-in group (<18)',
      onClick: () => goTo('/pre-approval-setup'),
      isHide: paymentFlowType.OLD,
    },
  ];

  return (
    <LbMenu
      buttonProps={{ endIcon: <KeyboardArrowDownRoundedIcon />, variant: 'contained' }}
      options={menuOptions}
    >
      Add New Customer
    </LbMenu>
  );
});

export { AddCustomerMenu };
