import React, { useMemo } from 'react';

import { Paper } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import classes from './MealPlan.module.scss';
import { NutrientInfoBlock } from './NutrientInfoBlock/NutrientInfoBlock';
import { MealHeaderRow, MealRow } from '../meal-table-components';
import { REGULAR_MEAL_NAME } from '../../../../../../shared/constants/mealNames';
import { TotalRow } from './TotalRow/TotalRow';
import { WaterSetupBlock } from './WaterSetupBlock/WaterSetupBlock';
import { getEnumArray } from '../../../../../../shared/utils/jsUtils';
import { MealInfoBlock } from './MealInfoBlock/MealInfoBlock';
import { MacroPlanStepFromValue } from '../NgzMealPlanTab.settings';
import { CaloriesPercentsRow } from './CaloriesPersentsRow/CaloriesPercentsRow';

type MealPlanProps = {
  nameIndex: number;
  isEditMode: boolean;
  setIsEditMode: (value: boolean) => void;
  onKeepClick: () => void;
  onSubmit: (isImmediately: boolean) => void;
};

const MealPlan = ({
  nameIndex,
  isEditMode,
  setIsEditMode,
  onKeepClick,
  onSubmit,
}: MealPlanProps) => {
  const { watch } = useFormContext<MacroPlanStepFromValue>();
  const mealsPerDay = watch(`mealPlans.${nameIndex || 0}.mealPlan`);
  const mealsList = useMemo(() => {
    switch (+mealsPerDay) {
      case 5:
        return getEnumArray(REGULAR_MEAL_NAME, [REGULAR_MEAL_NAME.snack1]);
      case 6:
      default:
        return getEnumArray(REGULAR_MEAL_NAME);
    }
  }, [mealsPerDay]);

  return (
    <div>
      <MealInfoBlock
        nameIndex={nameIndex}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        onKeepClick={onKeepClick}
        onSubmit={onSubmit}
      />
      <Paper variant="roundedBox" className={classes.mealTable}>
        <MealHeaderRow />
        {mealsList.map((meal) => (
          <MealRow
            meal={meal}
            mealFormKey={`mealPlans.${nameIndex}.${meal}`}
            key={meal}
            isEditMode={isEditMode}
          />
        ))}
        <TotalRow nameIndex={nameIndex} />
        {!isEditMode && <CaloriesPercentsRow nameIndex={nameIndex} />}
      </Paper>
      <NutrientInfoBlock nameIndex={nameIndex} />
      <WaterSetupBlock nameIndex={nameIndex} isEditMode={isEditMode} />
    </div>
  );
};

export { MealPlan };
