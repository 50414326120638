import React from 'react';

import { Collapse, Paper } from '@mui/material';
import { useToggle } from 'react-use';

import classes from './RecipeRow.module.scss';
import { Recipe } from '../../../../../../interfaces/CustomerRecipes';
import {
  GridTableRow,
  GridTableCell,
} from '../../../../../../../shared/components/table-components';
import {
  Nutrient,
  OpenCloseArrow,
} from '../../../../../../../shared/components/ui-components';
import { customerRecipeNutrientsConfig } from '../CustomerRecipesTable.settings';
import { IngredientRow } from './IngredientRow/IngredientRow';
import { getRoundedNumber } from '../../../../../../../shared/utils/mathUtils';

type RecipeRowProps = {
  recipe: Recipe;
};

const RecipeRow = ({ recipe }: RecipeRowProps) => {
  const [isShowIngredients, toggleShowIngredients] = useToggle(false);

  const { name, servingSize, totalMacros, ingredients } = recipe;

  return (
    <Paper variant="roundedBox" className={classes.box}>
      <GridTableRow
        variant="unstyled"
        dataTestId="recipe-row"
        onClick={toggleShowIngredients}
        className={classes.recipeRow}
      >
        <GridTableCell variant="title" className={classes.recipeInfoCell}>
          <OpenCloseArrow open={isShowIngredients} />
          <div>
            <h4>{name}</h4>
            <p className={classes.description}>{servingSize} Serving Size</p>
          </div>
        </GridTableCell>
        {customerRecipeNutrientsConfig.map((nutrient) => (
          <GridTableCell key={nutrient.valueKey}>
            <Nutrient nutrient={nutrient.valueKey}>
              {getRoundedNumber(totalMacros[nutrient.valueKey], 1)}
            </Nutrient>
          </GridTableCell>
        ))}
      </GridTableRow>
      <Collapse in={isShowIngredients} unmountOnExit>
        {ingredients.map((ingredient) => (
          <IngredientRow key={ingredient.id} ingredient={ingredient} />
        ))}
      </Collapse>
    </Paper>
  );
};

export { RecipeRow };
