import React from 'react';

import { Button } from '@mui/material';
import { observer } from 'mobx-react';

import classes from './ChangePasswordRow.module.scss';
import { getDescriptionInfo } from './utils/getDescriptionInfo';
import { loggedInUserDataStore } from '../../../../shared/stores/loggedInUserDataStore';
import { TitleWithDescription } from '../../../../shared/components/text-formation-components';

type ChangePasswordRowProps = {
  onPasswordChange: () => void;
};

const ChangePasswordRow = observer(({ onPasswordChange }: ChangePasswordRowProps) => {
  const { isSuperAdmin, isEntity } = loggedInUserDataStore;

  const { title, description } = getDescriptionInfo(isSuperAdmin || isEntity);
  return (
    <div className={classes.row}>
      <TitleWithDescription
        title={title}
        description={description}
        titleFont="body-14-bold"
        className={classes.title}
      />
      <Button variant="outlined" size="small" onClick={onPasswordChange}>
        Change Password
      </Button>
    </div>
  );
});

export { ChangePasswordRow };
