import React from 'react';

import { Divider } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import {
  GridTableCell,
  GridTableRow,
} from '../../../../../../../shared/components/table-components';
import { Nutrient } from '../../../../../../../shared/components/ui-components';
import { NUTRIENTS_ENUM } from '../../../../../../../shared/constants/NUTRIENTS_ENUM';
import { ReadOnlyInput } from '../../../../../../../shared/components/form-components';
import { useNutrientsTotal } from './hooks/useNutrientsTotal';
import { MacroPlanStepFromValue } from '../../NgzMealPlanTab.settings';

const nutrientsList = [
  NUTRIENTS_ENUM.PROTEINS,
  NUTRIENTS_ENUM.CARBS,
  NUTRIENTS_ENUM.FATS,
  NUTRIENTS_ENUM.CALORIES,
] as const;

type TotalRowProps = {
  nameIndex: number;
};

const TotalRow = ({ nameIndex }: TotalRowProps) => {
  const { control } = useFormContext<MacroPlanStepFromValue>();

  const total = useNutrientsTotal(nameIndex);

  return (
    <>
      <Divider />
      <GridTableRow variant="subRow">
        <GridTableCell variant="title">Total</GridTableCell>
        {nutrientsList.map((nutrient) => (
          <GridTableCell variant="title" key={nutrient}>
            <Nutrient nutrient={nutrient}>
              <ReadOnlyInput
                control={control}
                name={`mealPlans.${nameIndex}.total.${nutrient}`}
                value={total[nutrient]}
                valueFont="inherit"
              />
            </Nutrient>
          </GridTableCell>
        ))}
      </GridTableRow>
    </>
  );
};

export { TotalRow };
