import { ValueOfKey } from '../../../../utils-types';

export function getEnumArray<T extends Record<string, string>>(
  enumLikeObj: T,
): ValueOfKey<T>[];
export function getEnumArray<
  T extends Record<string, string>,
  TExclude extends Readonly<ValueOfKey<T>[]>,
>(enumLikeObj: T, exclude?: TExclude): Exclude<ValueOfKey<T>, TExclude[number]>[];

export function getEnumArray(enumLikeObj: any, exclude?: any) {
  const enumValues = Object.values(enumLikeObj);

  if (exclude) {
    return enumValues.filter((value) => !exclude.includes(value));
  }

  return enumValues;
}
