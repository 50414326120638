import { makeAutoObservable } from 'mobx';

class ApiStatusStore {
  isLoadingGlobal: boolean = false;
  errorMessage: string = '';
  isLoading: boolean = false;
  private fetchCallsCount: number = 0;
  private globalFetchCallsCount: number = 0;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDefaultValues() {
    this.isLoadingGlobal = false;
    this.errorMessage = '';
    this.isLoading = false;
    this.fetchCallsCount = 0;
    this.globalFetchCallsCount = 0;
  }

  loadingStart(isRunGlobalSpinner: boolean | undefined) {
    this.increaseFetchCallsCount();

    if (isRunGlobalSpinner) {
      this.increaseGlobalFetchCallsCount();
      this.globalLoadingStart();
    } else {
      this.localLoadingStart();
    }
  }

  private increaseFetchCallsCount() {
    this.fetchCallsCount++;
  }

  private increaseGlobalFetchCallsCount() {
    this.globalFetchCallsCount++;
  }

  private globalLoadingStart() {
    this.isLoadingGlobal = true;
  }

  private localLoadingStart() {
    this.isLoading = true;
  }

  loadingEnd(isRunGlobalSpinner: boolean | undefined) {
    this.decreaseFetchCallsCount();

    if (isRunGlobalSpinner) {
      this.decreaseGlobalFetchCallsCount();
    }

    if (!this.globalFetchCallsCount) {
      this.stopGlobalLoading();
    }

    if (!this.fetchCallsCount) {
      this.stopLoading();
      this.stopGlobalLoading();
    }
  }

  private decreaseFetchCallsCount() {
    this.fetchCallsCount--;
  }

  private decreaseGlobalFetchCallsCount() {
    this.globalFetchCallsCount--;
  }

  private stopLoading() {
    this.isLoading = false;
  }

  private stopGlobalLoading() {
    this.isLoadingGlobal = false;
  }

  addErrorMessage(errorMessage: string) {
    this.errorMessage = errorMessage;
  }

  cleanErrorMessage() {
    this.errorMessage = '';
  }
}

const apiStatusStore = new ApiStatusStore();

export { ApiStatusStore, apiStatusStore };
