import { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useToggle } from 'react-use';

import { loggedInUserDataStore } from '../../../../../../shared/stores/loggedInUserDataStore';
import { AffiliateInfo } from '../../../../../interfaces/AffiliateInfo';
import { AddAffiliate, affiliateService } from '../../../../../services/affiliateService';
import { updateItemByField } from '../../../../../../shared/utils/arrayUtils';

// TODO look maybe better reset function to store, look at used CorporateAccountsModal
export const useScholasticAffiliate = () => {
  const [affiliatesList, setAffiliatesList] = useState<AffiliateInfo[]>([]);
  const [isOpenModalAffiliate, toggleOpenModalAffiliate] = useToggle(false);
  const [editAffiliateId, setEditAffiliateId] = useState<number | null>(null);

  const entityId = loggedInUserDataStore.getEntityId();

  const { control, handleSubmit, setValue } = useForm<AddAffiliate>({
    shouldUnregister: true,
  });

  const onSubmit = async (affiliateInfo: AddAffiliate) => {
    if (editAffiliateId) {
      editAffiliate(affiliateInfo);
    } else {
      addNewAffiliate(affiliateInfo);
    }

    handleCloseModalAffiliate();
  };

  const addNewAffiliate = async (affiliateInfo: AddAffiliate) => {
    const newAffiliate = await affiliateService.addNewAffiliate(entityId, affiliateInfo);

    setAffiliatesList([newAffiliate, ...affiliatesList]);
  };

  const editAffiliate = async (affiliateInfo: AddAffiliate) => {
    const editedAffiliateInfo = await affiliateService.editAffiliate(
      entityId,
      editAffiliateId!,
      affiliateInfo,
    );

    updateAffiliate(editedAffiliateInfo);
  };

  const handleAffiliateActiveStatusChange = async (
    affiliateId: number,
    status: boolean,
  ) => {
    const resp = await affiliateService.changeAffiliateStatus(
      entityId,
      affiliateId,
      status,
    );

    updateAffiliate(resp);
  };

  const updateAffiliate = (newAffiliateInfo: AffiliateInfo) => {
    const editedAffiliateList = updateItemByField(affiliatesList, newAffiliateInfo, {
      id: newAffiliateInfo.id,
    });

    setAffiliatesList(editedAffiliateList);
  };

  const openAddNewAffiliateModal = () => {
    toggleOpenModalAffiliate();
  };

  const openEditAffiliateModal = (affiliateInfo: AffiliateInfo) => {
    const { id, name } = affiliateInfo;

    setEditAffiliateId(id);
    setValue('name', name);
    toggleOpenModalAffiliate();
  };

  const handleCloseModalAffiliate = () => {
    setEditAffiliateId(null);
    toggleOpenModalAffiliate();
  };

  const getAffiliateList = async () => {
    const { customerSchoolGroups } = await affiliateService.getAffiliateList(entityId);

    setAffiliatesList(customerSchoolGroups);
  };

  useEffect(() => {
    getAffiliateList();
  }, []);

  return {
    affiliatesList,
    isOpenModalAffiliate,
    editAffiliateId,
    control,
    handleFormSubmit: handleSubmit(onSubmit),
    openAddNewAffiliateModal,
    openEditAffiliateModal,
    handleCloseModalAffiliate,
    handleAffiliateActiveStatusChange,
  };
};
