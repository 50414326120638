import { getEnabledModules } from "../../modules/shared/utils/paidModulesUtils";

(function () {
    'use strict';

    angular.module('app.coachEdit').controller('CoachEditController', CoachEditController);

    CoachEditController.$inject = [
        '$scope',
        '$routeParams',
        'utilService',
        'ModalService',
        'CoachService',
        'GeneralService',
        'constants',
    ];

    function CoachEditController($scope, $routeParams, utilService, ModalService, CoachService,
                                 GeneralService, constants) {
        var vm = this;

        vm.loggedInUser = utilService.getLoggedInUserData();
        vm.states = constants.states;
        vm.patterns = constants.enums.patterns;
        vm.tabName = 'coachDetails';
        vm.coachPassword = {};
        vm.createCoach = {
            additional: {
                ableToGiveDiscount: false
            }
        };
        vm.initialCoach = {};
        vm.availableEntities = [];
        vm.spinner = {active: false};
        vm.isActiveCalendarEnabled = false;
        vm.paymentFlowType = {};

        vm.goBack = goBack;
        vm.activateOrDeactivateCoach = activateOrDeactivateCoach;
        vm.updateCoachInfo = updateCoachInfo;
        vm.checkUserEmail = checkUserEmail;
        vm.getCoachForEntity = getCoachForEntity;
        vm.showDeleteCoachDialog = showDeleteCoachDialog;
        vm.deleteCurrentUser = deleteCurrentUser;
        vm.setPaymentFlowType = setPaymentFlowType;
        vm.setIsActiveCalendarEnabled = setIsActiveCalendarEnabled;

        vm.getCoachForEntity();

        function goBack() {
            utilService.goBackWithParam('/coach-list', constants.pages.coachList);
        }

        function showDeleteCoachDialog() {
            ModalService.confirm('Are you sure you want to delete an user?',
                vm.deleteCurrentUser, $routeParams.id, null, 'Cancel', 'Delete')
        }

        function deleteCurrentUser(id) {
            vm.spinner.active = true;
            GeneralService.deleteUserBySuper(id).then(function (response) {
                vm.spinner.active = false;
                if (response.status === 200) {
                    utilService.goTo('/coach-list');
                }
            })
        }

        function activateOrDeactivateCoach() {
            GeneralService.disableEnableUser($routeParams.id, {active: !vm.createCoach.active}).then(function (res) {
                if (res.status === 200) {
                    vm.createCoach.active = res.data.active;
                }
            });
        }

        function updateCoachInfo() {
            if (!vm.createCoach.additional.ableToGiveDiscount) {
                vm.createCoach.additional.setupDiscountPercent = 0;
                vm.createCoach.additional.membershipDiscountPercent = 0;
                vm.createCoach.additional.renewalDiscountPercent = 0;
            }

            CoachService.updateCoachByEntity($routeParams.id, vm.createCoach).then(function (res) {
                if (res.status === 200) {
                    vm.initialCoach = angular.copy(vm.createCoach);
                    ModalService.savedChanges()
                }
            });
        }

        function checkUserEmail(form) {
            const isEmailChanged = vm.createCoach.email !== vm.initialCoach.email;

            if (vm.createCoach.email && isEmailChanged) {
                CoachService.checkUserEmail(vm.createCoach.email).then(function (res) {
                    form['email'].$setValidity('duplicate', res.status !== 200);
                });
            } else {
                form['email'].$setValidity('duplicate', true);
            }
        }

        function getCoachForEntity() {
            CoachService.getCoachForEntity($routeParams.id).then(function (res) {
                if (res.status === 200) {
                    vm.createCoach = res.data;
                    vm.coachName = angular.copy(vm.createCoach);
                    vm.initialCoach = angular.copy(vm.createCoach);
                    vm.availableEntities = [vm.createCoach.entity];

                    vm.setIsActiveCalendarEnabled();
                    vm.setPaymentFlowType();
                }
            });
        }

        function setPaymentFlowType() {
            const paymentFlowTypeConfig = constants.enums.paymentFlowType;
            const paymentFlowType = vm.createCoach?.entity?.paymentFlowType;

            vm.paymentFlowType = utilService.getFlagsObject(paymentFlowTypeConfig, [paymentFlowType])
        }

        async function setIsActiveCalendarEnabled() {
            const enabledModules = await getEnabledModules(vm.createCoach.gymId);
            vm.isActiveCalendarEnabled = enabledModules.ACTIVE_CALENDAR;

            $scope.$apply();
        }
    }
})();
