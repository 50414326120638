import React from 'react';

import { useAsyncFn } from 'react-use';
import { useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';

import { LbModal } from '../../../shared/components/modals';
import { LbInput } from '../../../shared/components/form-components';
import { pinCodeService } from '../../services/pinCodeService';
import { patterns } from '../../../shared/constants/patterns';

type SetUpPinCodeModalProps = {
  coachId: number;
  onClose: () => void;
  hasCurrentPinCode: boolean;
  setCurrentPinCode: (pinCode: string) => void;
};

type SetUpPinCodeFormValue = {
  pinCode: string;
};

const SetUpPinCodeModal = ({
  coachId,
  onClose,
  hasCurrentPinCode,
  setCurrentPinCode,
}: SetUpPinCodeModalProps) => {
  const { control, handleSubmit } = useForm<SetUpPinCodeFormValue>();

  const handlerPasswordChange = handleSubmit(async (data) => {
    await pinCodeService.saveCoachPinCode({
      coachId,
      hasPinCode: hasCurrentPinCode,
      newPinCode: data.pinCode,
    });

    setCurrentPinCode(data.pinCode);
    enqueueSnackbar({
      message: 'PIN code has been updated successfully',
      variant: 'success',
    });
    onClose();
  });
  const [status, handleFormSubmit] = useAsyncFn(handlerPasswordChange);

  return (
    <LbModal
      open
      title="Set up PIN Code"
      text="Enter the 4-digit code"
      primaryBtnConfig={{ text: 'Save', onClick: handleFormSubmit }}
      secondaryBtnConfig={{ text: 'Cancel', onClick: onClose }}
      isLoading={status.loading}
    >
      <form noValidate>
        <LbInput
          control={control}
          size="small"
          variant="outlined"
          label="PIN Code"
          name="pinCode"
          rules={{
            required: 'PIN code is required',
            pattern: {
              value: patterns.FOUR_DIGIT_NUMBER,
              message: 'The pin code is a 4-digit code',
            },
          }}
        />
      </form>
    </LbModal>
  );
};

export { SetUpPinCodeModal };
