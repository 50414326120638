import React from 'react';

import { useFormContext } from 'react-hook-form';
import { observer } from 'mobx-react';

import { MultipleComboBox } from '../../../../../../../../components/form-components';
import { CustomFilterFormValues } from '../../../AddCustomFilterForm.settings';
import { useAffiliateList } from '../../../../../../../../../scholastic';

const AffiliateGroupFilter = observer(() => {
  const { control } = useFormContext<CustomFilterFormValues>();

  const { affiliateListOption } = useAffiliateList({ isRunGlobalSpinner: false });

  return (
    <MultipleComboBox
      control={control}
      size="small"
      variant="outlined"
      name="filters.PERSONAL_INFO.schoolIds"
      label="Affiliate"
      options={affiliateListOption}
      limitTags={3}
    />
  );
});

export { AffiliateGroupFilter };
