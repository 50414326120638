import { makeAutoObservable } from 'mobx';
import { FilterStore } from '../../shared/util-stores';
import {
  CustomerRecipesFilterFormValues,
  CustomerRecipesFilters,
  Recipe,
} from '../interfaces/CustomerRecipes';
import { customerRecipesService } from '../services/customerRecipesService';
import { resetAllStore } from '../../shared/stores/resetAllStore';
import { paginationSmallListLimit } from '../../shared/constants/systemConstants';

class CustomerRecipesStore {
  private customerId: number | null = null;
  recipes: Recipe[] = [];
  filterMethods: FilterStore<CustomerRecipesFilterFormValues>;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    this.filterMethods = new FilterStore<CustomerRecipesFilterFormValues>(
      this.handleGetRecipes,
      { elementsPerPage: paginationSmallListLimit },
    );
  }

  setDefaultValues() {
    this.customerId = null;
    this.recipes = [];
    this.filterMethods.setDefaultValues();
  }

  getRecipes(customerId: number) {
    this.setCustomerId(customerId);
    return this.filterMethods.getData();
  }

  private async handleGetRecipes(filterData: CustomerRecipesFilters) {
    if (!this.customerId) {
      throw new Error('customerId is not set');
    }

    const data = await customerRecipesService.getCustomerRecipes(
      this.customerId,
      filterData,
    );

    this.setRecipes(data.content);

    return data;
  }

  private setCustomerId(customerId: number) {
    this.customerId = customerId;
  }

  private setRecipes(recipes: Recipe[]) {
    this.recipes = recipes;
  }
}

const customerRecipesStore = new CustomerRecipesStore();

resetAllStore.addResetMethod(customerRecipesStore.setDefaultValues);

export { customerRecipesStore, CustomerRecipesStore };
