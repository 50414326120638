import { SpringPagination } from '../../shared/interfaces/SpringPagination';
import { apiService } from '../../shared/services/apiService';
import { getFilteredTruthyFieldsObj } from '../../shared/utils/sortingAndGroupingUtil';
import { CustomerRecipesFilters, Recipe } from '../interfaces/CustomerRecipes';

type GetCustomerRecipesResponse = SpringPagination<Recipe[]>;

export const customerRecipesService = {
  async getCustomerRecipes(customerId: number, params: CustomerRecipesFilters) {
    const filteredParams = getFilteredTruthyFieldsObj(params);

    const resp = await apiService.post<GetCustomerRecipesResponse>(
      `/api/coach/users/${customerId}/recipes`,
      filteredParams,
    );

    return resp.data;
  },
};
