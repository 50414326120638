import React, { useEffect } from 'react';

import { angularize } from 'react-in-angularjs';
import { Container, Pagination } from '@mui/material';
import { observer } from 'mobx-react';

import classes from './NgzScholasticCustomerList.module.scss';
import { SystemProviders } from '../../../shared/providers';
import { ScholasticCustomerPageTitle } from './ScholasticCustomerPageTitle/ScholasticCustomerPageTitle';
import { TitleRow } from './TitleRow/TitleRow';
import { scholasticCustomersListStore } from '../../stores/ScholasticCustomersListStore';
import { FilterRow } from './FilterRow/FilterRow';
import { CustomerRow } from './CustomerRow/CustomerRow';
import { useCustomFilter } from '../../../shared/features/custom-filter';
import { loggedInUserDataStore } from '../../../shared/stores/loggedInUserDataStore';
import { EmptyList } from '../../../shared/components/ui-components';

type NgzScholasticCustomerListProps = {
  listTitle: string;
};

const NgzScholasticCustomerList = observer(
  ({ listTitle }: NgzScholasticCustomerListProps) => {
    const { initUserInfoFinished } = loggedInUserDataStore;
    const { customerList, getCustomerListInitData, filterMethod } =
      scholasticCustomersListStore;
    const {
      isShowPagination,
      isShowPlaceholder,
      isShowFilterRow,
      isSearchUsed,
      getPaginationProps,
    } = filterMethod;

    useCustomFilter('scholastic');

    useEffect(() => {
      if (initUserInfoFinished) {
        getCustomerListInitData();
      }
    }, [initUserInfoFinished]);

    const paginationProps = getPaginationProps();

    return (
      <SystemProviders>
        <Container>
          <ScholasticCustomerPageTitle title={listTitle} />
          <div className={classes.scholasticTable}>
            <TitleRow />
            {isShowFilterRow && <FilterRow />}
            {customerList.map((userInfo) => (
              <CustomerRow userInfo={userInfo} key={userInfo.id} />
            ))}
          </div>
          {isShowPagination && <Pagination {...paginationProps} />}
          <EmptyList
            show={isShowPlaceholder}
            isSearch={isSearchUsed}
            description="There are no customers"
          />
        </Container>
      </SystemProviders>
    );
  },
);

angularize(
  NgzScholasticCustomerList,
  'ngzScholasticCustomerList',
  angular.module('app.scholastic'),
  { listTitle: '<' },
);

export { NgzScholasticCustomerList };
