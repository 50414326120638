import { useEffect, useMemo, useState } from 'react';

import { affiliateService } from '../../../services/affiliateService';
import { loggedInUserDataStore } from '../../../../shared/stores/loggedInUserDataStore';
import { AffiliateInfo } from '../../../interfaces/AffiliateInfo';
import { transformToOptions, addAllOption } from '../../../../shared/utils/arrayUtils';
import { Option } from '../../../../general-types';

type UseAffiliateListReturn = {
  affiliateList: AffiliateInfo[];
  affiliateListOption: Option[];
  affiliateListOptionWithAll: Option[];
};

type UseAffiliateListProps = {
  onlyActive?: boolean;
  isRunGlobalSpinner?: boolean;
};

export const useAffiliateList = ({
  onlyActive = false,
  isRunGlobalSpinner,
}: UseAffiliateListProps = {}): UseAffiliateListReturn => {
  const [affiliateList, setAffiliateList] = useState<AffiliateInfo[]>([]);
  const { getEntityId, isSuperAdmin } = loggedInUserDataStore;

  const initAffiliateList = async () => {
    const { customerSchoolGroups } = await getAffiliateList();

    if (onlyActive) {
      const activeAffiliates = customerSchoolGroups.filter(
        (affiliate) => affiliate.active,
      );
      setAffiliateList(activeAffiliates);
    } else {
      setAffiliateList(customerSchoolGroups);
    }
  };

  const getAffiliateList = async () => {
    if (isSuperAdmin) {
      return affiliateService.getAffiliateForAllEntity(isRunGlobalSpinner);
    }

    const entityId = getEntityId();
    return affiliateService.getAffiliateList(entityId, isRunGlobalSpinner);
  };

  useEffect(() => {
    initAffiliateList();
  }, []);

  const affiliateListOption = useMemo(
    () => transformToOptions({ list: affiliateList, labelKey: 'name', valueKey: 'id' }),
    [affiliateList],
  );

  const affiliateListOptionWithAll = useMemo(
    () => addAllOption(affiliateListOption),
    [affiliateListOption],
  );

  return {
    affiliateList,
    affiliateListOption,
    affiliateListOptionWithAll,
  };
};
