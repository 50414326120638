export const ENTITY_PROFILE_TAB_NAME = {
  BUSINESS_INFO: 'BUSINESS_INFO',
  LEGAL_INFO: 'LEGAL_INFO',
  SCHOOL_FEES: 'SCHOOL_FEES',
  PASSWORD: 'PASSWORD',
  APP_COST: 'APP_COST',
  LIFE_BASE_FEES: 'LIFE_BASE_FEES',
  INBODY_INFO: 'INBODY_INFO',
  CONTACT_NUMBERS: 'CONTACT_NUMBERS',
  AFFILIATES: 'AFFILIATES',
  CUSTOMER_PRICING: 'CUSTOMER_PRICING',
  SUBSCRIPTIONS: 'SUBSCRIPTIONS',
  CERTIFICATES: 'CERTIFICATES',
  POS: 'POS',
  CORPORATE_ACCOUNT: 'CORPORATE_ACCOUNT',
  ADDITIONAL_INSURANCES: 'ADDITIONAL_INSURANCES',
  INVOICES_AND_PRICING: 'INVOICES_AND_PRICING',
  SALES_REP: 'SALES_REP',
  TAX: 'TAX',
  LAB_TESTS: 'LAB_TESTS',
  PAYMENT: 'PAYMENT',
  PROMO_CODES: 'PROMO_CODES',
  INTEGRATIONS: 'INTEGRATIONS',
  SETTINGS: 'SETTINGS',
  DETAILED_REVENUE: 'DETAILED_REVENUE',
};
