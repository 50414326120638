import React from 'react';

import { angularize } from 'react-in-angularjs';
import { observer } from 'mobx-react';

import { SystemProviders } from '../../../shared/providers';
import { CoachPasswordBlock } from '../../components/CoachPasswordBlock/CoachPasswordBlock';
import { loggedInUserDataStore } from '../../../shared/stores/loggedInUserDataStore';
import { CoachPinCodeBlock } from './CoachPinCodeBlock/CoachPinCodeBlock';

type NgzPasswordTabProps = {
  userId: number;
  userEmail: string;
};

const NgzPasswordTab = observer(({ userId, userEmail }: NgzPasswordTabProps) => {
  const { isCoach } = loggedInUserDataStore;

  return (
    <SystemProviders>
      {userId && userEmail && (
        <CoachPasswordBlock userId={userId} userEmail={userEmail} />
      )}
      {!isCoach && <CoachPinCodeBlock userId={userId} />}
    </SystemProviders>
  );
});

angularize(NgzPasswordTab, 'ngzPasswordTab', angular.module('app.authManagement'), {
  userId: '<',
  userEmail: '<',
});
