export const patterns = {
  PHONE: /^(\+\d{1,2}\s?)?(\(\d{3}\)|\d{3})[\s.-]?\d{3}[\s.-]?\d{4}$/,
  EMAIL:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  ONLY_WORD_CHARACTERS: /^\w+$/,
  ONLY_LETTERS: /^[a-zA-Z]+$/,
  // TODO add test for this pattern
  URL: /^(?:https?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.%]+$/,
  PROMO_CODE: /^[A-Z0-9]{4,25}$/,
  ZIP_CODE: /^\d{5}(-\d{4})?$/,
  FOUR_DIGIT_NUMBER: /^\d{4}$/,
};
