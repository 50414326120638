import React from 'react';

import classes from './TitleRow.module.scss';
import {
  GridTableRow,
  GridTableCell,
} from '../../../../../../../shared/components/table-components';
import { customerRecipeNutrientsConfig } from '../CustomerRecipesTable.settings';

const TitleRow = () => {
  return (
    <GridTableRow variant="header" className={classes.titleRow}>
      <GridTableCell justify="start">Recipe</GridTableCell>
      {customerRecipeNutrientsConfig.map((nutrient) => (
        <GridTableCell key={nutrient.id}>{nutrient.title}</GridTableCell>
      ))}
    </GridTableRow>
  );
};

export { TitleRow };
