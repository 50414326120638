export const CUSTOMER_PROFILE_TAB_NAMES = {
  CUSTOMER_OVERVIEW: 'CUSTOMER_OVERVIEW',
  WATER_GOAL: 'WATER_GOAL',
  ACTIVE_CALENDAR: 'ACTIVE_CALENDAR',
  LAB_TESTS: 'LAB_TESTS',
  CUSTOMER_PROFILE: 'CUSTOMER_PROFILE',
  CUSTOMER_MEAL_TIME: 'CUSTOMER_MEAL_TIME',
  CUSTOMER_COMPLIANCE_HISTORY: 'CUSTOMER_COMPLIANCE_HISTORY',
  CUSTOMER_MEAL_REPORTS: 'CUSTOMER_MEAL_REPORTS',
  MEAL_PLAN: 'MEAL_PLAN',
  CUSTOMER_GOAL: 'CUSTOMER_GOAL',
  IN_BODY_INDICATORS: 'IN_BODY_INDICATORS',
  CHECK_IN_HISTORY: 'CHECK_IN_HISTORY',
  PERFORMANCE_METRICS: 'PERFORMANCE_METRICS',
  CUSTOMER_RECIPES: 'CUSTOMER_RECIPES',
};
