import React from 'react';
import PropTypes from 'prop-types';

import { Paper } from '@mui/material';

import classes from './MealInfoTable.module.scss';
import { MealHeadRow } from './MealHeadRow/MealHeadRow';
import { MealTableTitle } from './MealTableTitle/MealTableTitle';
import { MealInfoRow } from './MealInfoRow/MealInfoRow';
import { ScrollBox, StarText } from '../../../shared/components/ui-components';

const MealInfoTable = ({ mealDetails, isReport, isModal }) => {
  const { name, foods = [], goal, consumed, score, userName, skipped } = mealDetails;

  const FoodRows = foods.map((food, index) => (
    // a user can add the same food multiple times, so the food id cannot be used as a key
    <MealInfoRow
      // eslint-disable-next-line react/no-array-index-key
      key={index}
      mealInfo={food}
      titleText={
        <div className={classes.foodTitle}>
          <span>{food.name}</span>
          {food.foodLabel && <span className={classes.label}>{food.foodLabel}</span>}
        </div>
      }
    />
  ));

  return (
    <Paper tabIndex={-1} variant="roundedBox" className={classes.complianceInfoTable}>
      <MealTableTitle
        isReport={isReport}
        isModal={isModal}
        isSkipped={skipped}
        mealName={name}
        userName={userName}
        scoreValue={score}
      />
      <MealHeadRow />
      <MealInfoRow
        withPoint={false}
        mealInfo={goal}
        titleText={<StarText>Goal</StarText>}
      />
      {isModal ? (
        <ScrollBox maxHeight={224}>{FoodRows}</ScrollBox>
      ) : (
        <div>{FoodRows}</div>
      )}
      <MealInfoRow mealInfo={consumed} titleText="Total" />
    </Paper>
  );
};

MealInfoTable.propTypes = {
  mealDetails: PropTypes.object.isRequired,
  isReport: PropTypes.bool,
  isModal: PropTypes.bool,
};

export { MealInfoTable };
