import { useEffect } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import { calculateSugarFromCarbs } from '../../../../../../../../shared/utils/mealUtils';
import { MacroPlanStepFromValue } from '../../../NgzMealPlanTab.settings';

export const useTotalSugar = (nameIndex: number) => {
  const { control, setValue } = useFormContext<MacroPlanStepFromValue>();
  const totalCarbs = useWatch({ control, name: `mealPlans.${nameIndex}.total.carbs` });

  useEffect(() => {
    const sugarTotal = calculateSugarFromCarbs(totalCarbs);

    setValue(`mealPlans.${nameIndex}.sugarTotal`, sugarTotal);
  }, [totalCarbs]);
};
