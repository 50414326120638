import React from 'react';

import classes from './TimingChoiceModal.module.scss';
import { LbModal } from '../../../shared/components/modals';

type TimingChoiceModalProps = {
  open: boolean;
  title: string;
  text?: string;
  onSubmit: () => void;
  onTomorrowSubmit: () => void;
  onClose: () => void;
};

export const TimingChoiceModal = ({
  open,
  title,
  text,
  onSubmit,
  onTomorrowSubmit,
  onClose,
}: TimingChoiceModalProps) => {
  return (
    <LbModal open={open} title={title} text={text}>
      <div className={classes.container}>
        <div className={classes.option} onClick={onSubmit}>
          <h6 className={classes.optionTitle}>Update Immediately</h6>
          <p className={classes.optionDescription}>
            Current foods entered in the App today may be erased
          </p>
        </div>
        <div className={classes.option} onClick={onTomorrowSubmit}>
          <h6 className={classes.optionTitle}>Apply Changes Tomorrow</h6>
          <p className={classes.optionDescription}>Current day will stay the same</p>
        </div>
        <div className={classes.option} onClick={onClose}>
          <h6 className={classes.optionTitle}>Cancel</h6>
          <p className={classes.optionDescription}>Do not make any changes</p>
        </div>
      </div>
    </LbModal>
  );
};
