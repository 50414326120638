import React from 'react';

import { Tab, TabsList } from '@mui/base';
import classNames from 'classnames';
import { IconButton } from '@mui/material';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import classes from './LbTabsList.module.scss';
import { TabOption, TabsConfig } from '../types/TabsConfig';

type LbTabsListProps = {
  tabsConfig: TabOption[] | TabsConfig[];
  variant: 'underline' | 'toggle' | 'horizontal-page' | 'vertical-page';
  fullWidth?: boolean;
  className?: string;
  children?: React.ReactNode;
};

const LbTabsList = ({
  tabsConfig,
  variant,
  fullWidth,
  className,
  children,
}: LbTabsListProps) => {
  const listClasses = classNames(className, classes[variant], {
    [classes.fullWidth]: fullWidth,
  });

  return (
    <TabsList className={listClasses}>
      {tabsConfig.map(
        (tab) =>
          !tab.hide && (
            <div className={classes.tabWrapper} key={tab.value}>
              <Tab value={tab.value} className={classes.tab} disabled={tab.disabled}>
                {!!tab.icon && <span className={classes.icon}>{tab.icon}</span>}
                <span>
                  <span>{tab.label}</span>
                </span>
              </Tab>
              {!!tab.onDelete && (
                <IconButton
                  onClick={tab.onDelete}
                  size="medium"
                  color="secondary"
                  className={classes.deleteBtn}
                >
                  <ClearRoundedIcon />
                </IconButton>
              )}
            </div>
          ),
      )}
      {children && <div>{children}</div>}
    </TabsList>
  );
};

export { LbTabsList, LbTabsListProps };
