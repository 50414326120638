import { TotalMacrosNutrient } from '../../../../../interfaces/CustomerRecipes';

type CustomerRecipeNutrientConfig = {
  id: number;
  title: string;
  valueKey: TotalMacrosNutrient;
};

export const customerRecipeNutrientsConfig: CustomerRecipeNutrientConfig[] = [
  { id: 1, title: 'Proteins', valueKey: 'proteins' },
  { id: 2, title: 'Carbs', valueKey: 'carbs' },
  { id: 3, title: 'Fats', valueKey: 'fats' },
  { id: 4, title: 'Sugar', valueKey: 'sugar' },
  { id: 5, title: 'Fiber', valueKey: 'fiber' },
  { id: 6, title: 'Veggies', valueKey: 'veggies' },
  { id: 7, title: 'Sodium', valueKey: 'sodium' },
  { id: 8, title: 'Calories', valueKey: 'calories' },
];
