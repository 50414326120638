import React, { useState } from 'react';

import { angularize } from 'react-in-angularjs';
import { Button } from '@mui/material';
import { observer } from 'mobx-react';
import { FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { enqueueSnackbar } from 'notistack';

import classes from './NgzMealPlanTab.module.scss';
import { SystemProviders } from '../../../../../shared/providers';
import {
  LbTabPanel,
  LbTabs,
  LbTabsList,
  TabsConfig,
} from '../../../../../shared/components/tabs';
import { getRouteParam } from '../../../../../shared/utils/angularUtils';
import { WorkoutMeals } from './WorkoutMeals/WorkoutMeals';
import { MealPlan } from './MealPlan/MealPlan';
import { mealPlanService } from '../../../../services/mealPlanService';
import { MealPlansMacros } from '../../../../interfaces/MealPlan';
import { openConfirmationModal } from '../../../../../shared/features/confirmation';
import { TimingChoiceModal } from '../../../../components/TimingChoiceModal/TimingChoiceModal';
import { generateMealPlanName } from './utils/generateMealPlanName';
import { TitleWithDescription } from '../../../../../shared/components/text-formation-components';
import { MacroPlanStepFromValue } from './NgzMealPlanTab.settings';
import { deleteItemByField } from '../../../../../shared/utils/arrayUtils';
import { useConfirm } from '../../../../../shared/hooks/useConfirm';

// This component is copy from MacroPlanStep almost the same
const NgzMealPlanTab = observer(() => {
  const [isSavedModalOpen, setIsSavedModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(2);
  const { ask: getIsChangeTab, answer: setIsChangeTab } = useConfirm<boolean>();

  const customerId = getRouteParam('id');
  const formMethods = useForm<MacroPlanStepFromValue>({
    defaultValues: async () => mealPlanService.getMealPlansMacros(customerId),
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = formMethods;

  const { fields, append } = useFieldArray({ control, name: 'mealPlans' });
  const mealPlans = useWatch({
    control,
    name: 'mealPlans',
  });
  const isAddButtonDisabled = mealPlans && !mealPlans[activeTab - 2]?.originId;

  const confirmChangeTab = async (onChange: () => void) => {
    if (isEditMode && isDirty) {
      setIsSavedModalOpen(true);

      const isChange = await getIsChangeTab();

      if (isChange) {
        onChange();
      }
    } else {
      onChange();
    }
  };

  const handleAddMealPlan = async () => {
    if (mealPlans.length === 20) {
      enqueueSnackbar('You have reached the limit for the number of meal plans. saved', {
        variant: 'error',
      });
      return;
    }

    confirmChangeTab(addNewMealPlan);
  };

  const addNewMealPlan = async () => {
    const result = await mealPlanService.buildMealPlansMacros(customerId);

    if (result?.mealPlans?.length > 0) {
      const newMealPlan = result.mealPlans[0];
      const originName = generateMealPlanName(mealPlans);

      const formattedMealPlan = {
        ...newMealPlan,
        originName,
        name: originName,
      };

      append(formattedMealPlan);
      setActiveTab(mealPlans.length + 2);
      setIsEditMode(true);
    } else {
      enqueueSnackbar('Error adding meal plan', { variant: 'error' });
    }
  };

  const handlePlanDelete = async (originId: number, tabIndex: number) => {
    const currentValues = formMethods.getValues();

    const updatedMealPlans = deleteItemByField(currentValues.mealPlans, { originId });
    const updatedData: MealPlansMacros = {
      ...currentValues,
      mealPlans: updatedMealPlans,
      isImmediately: true,
    };

    const resp = await mealPlanService.updateMealPlansMacros(customerId, updatedData);

    reset(resp);

    const deletedCurrentPlan = activeTab === tabIndex;

    if (deletedCurrentPlan) {
      setIsEditMode(false);
      setActiveTab(2);
    } else if (activeTab > tabIndex) {
      setActiveTab(activeTab - 1);
    }
  };

  const handleKeepClick = () => {
    reset();
    setIsEditMode(false);
  };

  const onSubmit = (isImmediately: boolean) =>
    handleSubmit(async (data: MealPlansMacros) => {
      const resp = await mealPlanService.updateMealPlansMacros(customerId, {
        ...data,
        isImmediately,
      });
      reset(resp);
      enqueueSnackbar('Changes saved', { variant: 'success' });
      setIsEditMode(false);
    })();

  const workoutTabConfig: TabsConfig = {
    label: 'Pre/Post Workout',
    value: 1,
    panel: (
      <WorkoutMeals
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        onKeepClick={handleKeepClick}
        onSubmit={onSubmit}
      />
    ),
  };

  const mealPlanTabsConfig: TabsConfig[] = fields.map((mealPlan, index) => ({
    label: (
      <TitleWithDescription
        title={mealPlan.name}
        description={mealPlan.originName}
        titleFont="inherit"
        withoutGap
      />
    ),
    value: index + 2, // +2 because for workout set value 1, (tabs defaultValue 2 show initially meal plan tab)
    panel: (
      <MealPlan
        nameIndex={index}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        onKeepClick={handleKeepClick}
        onSubmit={onSubmit}
      />
    ),
    ...(!mealPlan.root_plan && {
      onDelete: () => {
        openConfirmationModal({
          title: `Are you sure you want to delete this ${mealPlan.name}?`,
          confirmBtnConfig: {
            text: 'Delete',
            onClick: () => handlePlanDelete(mealPlan.originId, index + 2),
          },
        });
      },
    }),
  }));

  const tabsConfigs = [workoutTabConfig, ...mealPlanTabsConfig];

  const onChangeTab = async (newValue: string | number | null) => {
    if (!newValue) {
      throw new Error('unknown tab value');
    }

    confirmChangeTab(() => setActiveTab(+newValue));
  };

  const handelSaveUpdates = async (isImmediately: boolean) => {
    setIsSavedModalOpen(false);
    await onSubmit(isImmediately);
    setIsChangeTab(true);
  };

  if (!formMethods.formState.defaultValues) {
    return null;
  }

  return (
    <SystemProviders>
      <FormProvider {...formMethods}>
        <LbTabs
          value={activeTab}
          onChange={(e, newValue) => onChangeTab(newValue)}
          orientation="vertical"
          className={classes.tabsBox}
        >
          <LbTabsList tabsConfig={tabsConfigs} variant="vertical-page">
            <Button
              disabled={isAddButtonDisabled}
              variant="outlined"
              className={classes.addMealButton}
              startIcon={<AddRoundedIcon />}
              onClick={handleAddMealPlan}
            >
              Add Meal Plan
            </Button>
          </LbTabsList>
          <LbTabPanel tabsConfig={tabsConfigs} />
        </LbTabs>
      </FormProvider>
      <TimingChoiceModal
        open={isSavedModalOpen}
        title="Are you sure you want to go to another Meal Plan?"
        text="Your edits will be lost unless you click 'Update Immediately' or 'Apply Changes Tomorrow.'"
        onSubmit={async () => {
          handelSaveUpdates(true);
        }}
        onTomorrowSubmit={async () => {
          handelSaveUpdates(false);
        }}
        onClose={() => {
          setIsSavedModalOpen(false);
          setIsChangeTab(false);
        }}
      />
    </SystemProviders>
  );
});

angularize(
  NgzMealPlanTab,
  'ngzMealPlanTab',
  angular.module('app.customerManagement'),
  {},
);
