import { invert } from 'lodash';

import { apiService } from '../../shared/services/apiService';
import {
  renameFields,
  sortArrAlphabetically,
} from '../../shared/utils/sortingAndGroupingUtil';
import {
  DefaultPrePostWorkoutMealInfo,
  CreateWorkoutMealPlan,
  MealPlansMacros,
  MealPlan,
} from '../interfaces/MealPlan';
import { NUTRIENTS_ENUM } from '../../shared/constants/NUTRIENTS_ENUM';
import { REGULAR_MEAL_NAME } from '../../shared/constants/mealNames';

const fixFieldsNameConfig = {
  // backend field name: frontend field name
  crb: NUTRIENTS_ENUM.CARBS,
  fat: NUTRIENTS_ENUM.FATS,
  prt: NUTRIENTS_ENUM.PROTEINS,
  breakFast: REGULAR_MEAL_NAME.breakfast,
} as const;

const processMealPlansMacros = (mealPlansMacros: any): MealPlansMacros => {
  const renamedMealPlansMacros = renameFields(
    mealPlansMacros,
    fixFieldsNameConfig,
  ) as MealPlansMacros;

  const sortedMealPlans = sortArrAlphabetically(
    renamedMealPlansMacros.mealPlans,
    'originName',
  );

  return { ...renamedMealPlansMacros, mealPlans: sortedMealPlans };
};

export const mealPlanService = {
  async getDefaultPrePostWorkoutMeals() {
    const resp = await apiService.get<DefaultPrePostWorkoutMealInfo>(
      `api/coach/meal-plan/pre-post-workout`,
    );

    return resp.data;
  },

  async createWorkoutMealPlan(
    customerId: number,
    workoutMealPlan: CreateWorkoutMealPlan,
  ) {
    const workoutMealPlanData = renameFields(
      workoutMealPlan,
      invert(fixFieldsNameConfig),
    );

    const resp = await apiService.post(
      `api/coach/user/${customerId}/pre-post-workout`,
      workoutMealPlanData,
    );

    return processMealPlansMacros(resp.data);
  },

  async getMealPlansMacros(customerId: number) {
    const resp = await apiService.get(`api/coach/user/${customerId}/mealplan`);

    return processMealPlansMacros(resp.data);
  },

  async buildMealPlansMacros(customerId: number, mealsPerDay?: number) {
    const resp = await apiService.get(`api/coach/user/${customerId}/mealplan/build`, {
      params: { mealsPerDay },
    });

    return processMealPlansMacros(resp.data);
  },

  async updateMealPlansMacros(customerId: number, mealPlansMacros: MealPlansMacros) {
    const mealPlansMacrosData = renameFields(
      mealPlansMacros,
      invert(fixFieldsNameConfig),
    );

    const resp = await apiService.put(
      `/api/coach/user/${customerId}/mealplan`,
      mealPlansMacrosData,
    );

    return processMealPlansMacros(resp.data);
  },

  async switchAmountOfMeals(
    customerId: number,
    mealPlanOrigin: number,
    mealsPerDay?: number,
  ) {
    const resp = await apiService.put(
      `api/coach/user/${customerId}/switchmeal`,
      undefined,
      { params: { mealPlanOrigin, mealsPerDay } },
    );

    return renameFields(resp.data, fixFieldsNameConfig) as MealPlan;
  },
};
