import { MealPlan } from '../../../../../interfaces/MealPlan';

export const generateMealPlanName = (mealPlans: MealPlan[]) => {
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

  // eslint-disable-next-line no-restricted-syntax
  for (const letter of letters) {
    const exists = mealPlans.find((plan) => plan.originName === `Meal Plan ${letter}`);
    if (!exists) {
      return `Meal Plan ${letter}`;
    }
  }
  return 'Meal Plan';
};
