import React from 'react';
import { Nutrient } from '../../../../../../../shared/components/ui-components';
import { MealNutrientsInfo } from '../../../../../../interfaces/MealPlan';
import { NUTRIENTS_ENUM } from '../../../../../../../shared/constants/NUTRIENTS_ENUM';

const nutrientsList = [
  NUTRIENTS_ENUM.PROTEINS,
  NUTRIENTS_ENUM.CARBS,
  NUTRIENTS_ENUM.FATS,
  NUTRIENTS_ENUM.CALORIES,
] as const;

type MealNutrientsProps = {
  nutrientsInfo: MealNutrientsInfo;
};

const MealNutrients = ({ nutrientsInfo }: MealNutrientsProps) => {
  return nutrientsList.map((nutrient) => (
    <Nutrient nutrient={nutrient} key={nutrient}>
      {nutrientsInfo[nutrient]}
    </Nutrient>
  ));
};

export { MealNutrients };
