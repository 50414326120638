import React from 'react';

import { Button } from '@mui/material';

import { PageTitle } from '../../../../../../shared/components/ui-components';

type AffiliatesTitleProps = {
  onShowModalAffiliate: () => void;
};

const AffiliatesTitle = ({ onShowModalAffiliate }: AffiliatesTitleProps) => {
  return (
    <PageTitle title="Affiliate" customTopMargin>
      <Button
        variant="contained"
        onClick={() => {
          onShowModalAffiliate();
        }}
      >
        Add new Affiliate
      </Button>
    </PageTitle>
  );
};

export { AffiliatesTitle };
