import { apiService } from '../../shared/services/apiService';

const baseUrl = 'api/admin/pincode/';

type GetCoachPinCodeResponse =
  | {
      id: number;
      pincode: string;
      created: string; // example '01.17.2025'
    }
  | undefined;

type SaveCoachPinCodeParams = {
  coachId: number;
  hasPinCode: boolean;
  newPinCode: string;
};

export const pinCodeService = {
  async getCoachPinCode(coachId: number): Promise<GetCoachPinCodeResponse> {
    try {
      const resp = await apiService.get(`${baseUrl}${coachId}`, {
        ignoreErrorCodes: [404],
      });

      return resp.data;
    } catch (error) {
      console.error('Error fetching coach pin code:', error);
      return undefined;
    }
  },
  async saveCoachPinCode({
    coachId,
    hasPinCode,
    newPinCode,
  }: SaveCoachPinCodeParams): Promise<GetCoachPinCodeResponse> {
    const apiMethod = hasPinCode ? 'put' : 'post';

    const resp = await apiService[apiMethod](`${baseUrl}${coachId}`, {
      pincode: newPinCode,
    });

    return resp.data;
  },
};
