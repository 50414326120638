import { enqueueSnackbar } from 'notistack';
import { UseFormReturn, useForm } from 'react-hook-form';

import { ChangePasswordInfo, passwordService } from '../../../services/passwordService';
import { RESPONSE_STATUSES } from '../../../../shared/constants/RESPONSE_STATUSES';

export type ChangePasswordFormValues = ChangePasswordInfo;

type UseChangePasswordFormReturn = {
  handlerPasswordChange: () => Promise<void>;
  validateConfirmPassword: (
    confirmPassword: string,
    formValue: ChangePasswordFormValues,
  ) => boolean | string;
} & UseFormReturn<ChangePasswordInfo>;

type UseChangePasswordFormProps = {
  userId: number;
  isChangeCurrentUserPassword: boolean;
  onSuccessChange: () => void;
};

export const useChangePasswordForm = ({
  userId,
  isChangeCurrentUserPassword,
  onSuccessChange = () => {},
}: UseChangePasswordFormProps): UseChangePasswordFormReturn => {
  const formMethods = useForm<ChangePasswordFormValues>();
  const { setError, resetField, handleSubmit } = formMethods;

  const setNewPassword = async (data: ChangePasswordFormValues) => {
    const { currentPassword, ...passwordInfo } = data;
    await passwordService.setNewPasswordToUser(userId, passwordInfo);

    enqueueSnackbar('Password has been successfully changed.', {
      variant: 'success',
    });
  };

  const changePassword = async (passwordInfo: ChangePasswordFormValues) => {
    try {
      await passwordService.changePassword(passwordInfo, {
        ignoreErrorCodes: [RESPONSE_STATUSES.PASSWORD_INCORRECT],
      });

      enqueueSnackbar('Please use the new password when logging in', {
        variant: 'success',
      });
    } catch (error: any) {
      // all error statuses process by interceptor except PASSWORD_INCORRECT
      processError(error);
      throw error;
    }
  };

  const processError = (error: any) => {
    if (error.response?.status === RESPONSE_STATUSES.PASSWORD_INCORRECT) {
      resetField('currentPassword');
      setError('currentPassword', { message: 'Current password is wrong' });
    }
  };

  const handlerPasswordChange = handleSubmit(async (data: ChangePasswordFormValues) => {
    if (isChangeCurrentUserPassword) {
      await changePassword(data);
    } else {
      await setNewPassword(data);
    }

    onSuccessChange();
  });

  const validateConfirmPassword = (
    confirmPassword: string,
    formValue: ChangePasswordFormValues,
  ) => {
    return confirmPassword === formValue.password || "Passwords don't match";
  };

  return {
    ...formMethods,
    handlerPasswordChange,
    validateConfirmPassword,
  };
};
