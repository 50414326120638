import React from 'react';

import { useToggle } from 'react-use';
import { observer } from 'mobx-react';

import { InfoBox } from '../../../../../../../shared/components/ui-components';
import { SendMagicLinkRow } from './SendMagicLinkRow/SendMagicLinkRow';
import { loggedInUserDataStore } from '../../../../../../../shared/stores/loggedInUserDataStore';
import { ChangePasswordRow } from './ChangePasswordRow/ChangePasswordRow';
import { ChangePasswordModal } from '../../../../../../components/ChangePasswordModal/ChangePasswordModal';

type EntityPasswordBlockProps = {
  entityId: number;
  entityUserId: number;
};
const EntityPasswordBlock = observer(
  ({ entityUserId, entityId }: EntityPasswordBlockProps) => {
    const { isSuperAdmin, isEntity } = loggedInUserDataStore;
    const [isShowChangePasswordModal, toggleShowChangePasswordModal] = useToggle(false);

    return (
      <InfoBox title="Set New Password">
        {isSuperAdmin && <SendMagicLinkRow entityId={entityId} />}
        <ChangePasswordRow onPasswordChange={toggleShowChangePasswordModal} />
        {isShowChangePasswordModal && (
          <ChangePasswordModal
            userId={entityUserId}
            isChangeCurrentUserPassword={isEntity}
            onClose={toggleShowChangePasswordModal}
          />
        )}
      </InfoBox>
    );
  },
);

export { EntityPasswordBlock };
