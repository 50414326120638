import { apiService } from '../../shared/services/apiService';

const getChatInfo = async () => {
  const resp = await apiService('api/chat-info');
  return resp.data;
};

const getCustomerChatContacts = async () => {
  const resp = await apiService('api/chat-contacts');
  return resp.data;
};

const getCoachContactsChatInfo = async (coachId) => {
  const resp = await apiService(`api/chat-info/coach/${coachId}/customers`);
  return resp.data;
};

const refreshUsers = async () => {
  await apiService.post('api/chat-info/refresh-users', undefined, {
    isRunGlobalSpinner: false,
  });
};

const getChannelId = async (customerId) => {
  const params = { recipientId: customerId };

  const resp = await apiService('api/chat-info/channel/id', { params });
  return resp.data;
};

const sendMessage = async (message, recipientsIds, attachmentFileIds = []) => {
  const body = { message, recipientsIds, attachmentFileIds };

  const resp = await apiService.post('api/messaging/chat/send-message', body);
  return resp.data;
};

export const chatService = {
  getChatInfo,
  getCustomerChatContacts,
  getCoachContactsChatInfo,
  refreshUsers,
  getChannelId,
  sendMessage,
};
